<template>
  <div v-loading="loading">
    <el-dialog
      title="Funds Wd"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.user_id"
              label="User ID"
              prop="user_id"
              :label-width="formLabelWidth"
            >   
              <el-select
                v-model="formData.user_id"
                clearable
                filterable
                placeholder="Select"
              >
                <el-option label="001  User one" value="001"> </el-option>
                <el-option label="002  User two" value="002"> </el-option>
                <el-option label="0003 User three" value="003"> </el-option>
              </el-select>

             
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.notes"
              label="Notes"
              prop="notes"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.notes" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.amount"
              label="Amount"
              prop="amount"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.amount" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.transaction_password"
              label="Transaction Password"
              prop="transaction_password"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="formData.transaction_password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog()">Cancel</el-button>
        <el-button type="info" @click="sendChildFormData()">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { requiredRule, numberRule } from "@/rules/all-rules";

export default {
  name: "AddEditDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
   
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
    };
  },
  computed: {
   
  },
 created() {
    this.formRules.user_id = requiredRule("user_id");
    this.formRules.amount = numberRule("amount", ["required"]);
    this.formRules.transaction_password = requiredRule("transaction_password");
  },
  methods: {
    handleCloseDialog() {
      this.$emit("childClose");
    },
    sendChildFormData() {
      this.loading = true;
      //   console.log(this.formData)
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // WHEN EDIT MERGE NEW PARAMETERS
          if (this.dialogType === "edit") {
            this.formData = Object.assign(this.formData, {
              dialog_type: this.dialogType,
            });
          }
          this.$emit("getChildFormData", this.formData);
          this.loading = false;
        }
      });
    },
  },
};
</script>
