<template>
  <div v-loading="loading">
    <el-dialog
      title="Funds Wd"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
    <div class="funds-modal">
        <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                 <el-button type="info" @click="handlePopUpDialog" plain>Add or Withdraw Fund</el-button>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                 <el-button type="info" @click="handleMarketWatch" plain>Trade for Clients</el-button>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                 <el-button type="info" @click="handleTradingClient" plain>Create or Modify Account</el-button>
            </el-col>
        </el-row>
    </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog()">Close</el-button>
      </span> -->
    </el-dialog>

     <TradeDialog
      :dialog-visible.sync="tradeDialogVisible"
      @childClose="handleChildClose()"
    />
  </div>
</template>

<script>
import  TradeDialog  from '@/components/Dialog/TradeDialog.vue'
import { mapGetters } from "vuex";
import getRoleSlug from '@/store/app.js';

export default {
  name: "AddEditDialog",
  components: {
      TradeDialog
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      tradeDialogVisible: false,
      formRules: {},
      formLabelWidth: "120px",
    };
  },
  computed: {
     ...mapGetters(["getRoleSlug"]),
  },
  created() {},
  methods: {
    handleCloseDialog() {
      this.$emit("childClose");
    },
     // //////////////////
    // CREATE
    // //////////////////
    handlePopUpDialog() {
      console.log('hit')
      this.tradeDialogVisible = true;
      this.handleCloseDialog()
    },
    handleMarketWatch() {
      this.$router.push("/"+ getRoleSlug.state.roleSlug +"/market-watch");
    },
    handleTradingClient() {
      this.$router.push("/"+ getRoleSlug.state.roleSlug +"/trading-clients");
    },
    handleChildClose() {
      this.tradeDialogVisible = false;
    },
  },
};
</script>

